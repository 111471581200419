<script lang="ts">
    import { createCheckbox, melt } from '@melt-ui/svelte';
    import { Check, Minus } from 'lucide-svelte';

    export let required = false;
    export let id = '';
    export let name = '';
    
  
    const {
      elements: { root, input },
      helpers: { isChecked, isIndeterminate },
    } = createCheckbox({
      required: required,
    });

    let checked;
  $: checked = $isChecked;
  export { checked };
  </script>
  
  
    <div class="flex items-center">
      <button
        id={id}
        name={name}
        use:melt={$root}
        class="flex items-center justify-center w-6 h-6 text-black rounded-md shadow appearance-none stroke-2 bg-zinc-100 data-[state='unchecked']:hover:opacity-75 hover:text-white data-[state='checked']:hover:bg-gradient-to-t from-orange-500 to-orange-300"
      >
        {#if $isIndeterminate}
          <Minus class="w-5 h-5" />
        {:else if $isChecked}
          <Check class="w-5 h-5 " />
        {/if}
        <input use:melt={$input} />
      </button>
      <span class="flex justify-center h-6 mx-2"><slot /></span>
        
    </div>
    
  